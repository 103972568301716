import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./SignUpPage.css";
import apiService from "../../services/apiService";
import { BsXCircle } from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";

function SignUpPage() {
    const navigate = useNavigate();
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [gender, setGender] = useState("");
    const [dob, setDob] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [renderFlag, setRenderFlag] = useState("");
    const location = useLocation();
    const userData = apiService.getData();
    const handleDateChange = (date) => {
        setDob(date);
    };

    const onClose = () => {
        navigate("/login")
    }
    const saveUserandProceed = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const body = {
                _id: userData.uid,
                phone: userData.phoneNumber,
                firstName: firstName,
                lastName: lastName,
                email: email,
                gender: gender,
                dob: dob
            };
            const res = await apiService.post('/user/addUser', body);
            if (res.status === 200) {
                // Update local storage with the new user data
                apiService.setData(body);

                switch (renderFlag) {
                    case "event":
                        window.location.href = "https://hfs-live-31172.web.app";
                        break;
                    case "login":
                        navigate("/");
                        break;
                    case "ticket":
                        navigate("/paymentgateway");
                        break;
                    default:
                        navigate("/");
                        break;
                }
            }
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false); 
        }
    };
    const handleRegistration = (e) => {
        e.preventDefault();
        alert("Registration Form Submitted");
    }

    useEffect(() => {
        if (location.state && location.state.renderFlag) {
            console.log(location.state.renderFlag)
            setRenderFlag(location.state.renderFlag);
        }
    }, [location.state]);


    return (
        <>
            <div className="personaldet-modal" id="desktopView">
                <div className="containerr h-100">
                    <div className="cont-pers">
                        <span onClick={onClose}>
                            <BsXCircle className="closebtn bg-white rounded-pill" />
                        </span>
                        <div className="row p-3">
                            <div>
                                <h5 className="mt-3">
                                    <span className="fw-bold">Book</span> <span className="your-fs"> Your Front Seat</span>
                                </h5>
                            </div>
                            <h4 className="fw-bold text-start mt-3">Personal Details</h4>
                            <div>
                                <input
                                    className="form-control border-primary mb-3"
                                    type="text"
                                    id="first-name"
                                    placeholder="First Name"
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                />
                            </div>
                            <div>
                                <input
                                    className="form-control border-primary mb-3"
                                    type="text"
                                    id="last-name"
                                    placeholder="Last Name"
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                />
                            </div>
                            <div>
                                <input
                                    className="form-control border-primary"
                                    type="text"
                                    id="email"
                                    placeholder="Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                            <p className="mail-recieve">The email will receive the m-ticket</p>
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-6 text-start">
                                    <div className="d-flex justify-content-between">
                                        <div className="gender">
                                            <input
                                                className="pers-selection"
                                                type="radio"
                                                id="female"
                                                name="gender"
                                                value="female"
                                                checked={gender === "female"}
                                                onChange={() => setGender("female")}
                                            />
                                            <label className="pers-gender" htmlFor="female">
                                                Female
                                            </label>
                                        </div>
                                        <div className="gender">
                                            <input
                                                className="pers-selection"
                                                type="radio"
                                                id="male"
                                                name="gender"
                                                value="male"
                                                checked={gender === "male"}
                                                onChange={() => setGender("male")}
                                            />
                                            <label className="pers-gender" htmlFor="male">
                                                Male
                                            </label>
                                        </div>
                                    </div>
                                    {/* <div className="d-flex justify-content-center">
                                        <div className="gender m-auto">
                                            <input
                                                className="pers-selection"
                                                type="radio"
                                                id="other"
                                                name="gender"
                                                value="other"
                                                checked={gender === "other"}
                                                onChange={() => setGender("other")}
                                            />
                                            <label className="pers-gender" htmlFor="others">
                                                Others
                                            </label>
                                        </div>
                                    </div> */}
                                </div>
                                <div className="col-lg-6 col-md-6 col-6">
                                    <DatePicker
                                        className="form-control border-primary ms-lg-2"
                                        selected={dob}
                                        id="dob"
                                        onChange={handleDateChange}
                                        placeholderText="DOB    DD/MM/YYYY"
                                        dateFormat="dd/MM/yyyy"
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                    />
                                </div>
                            </div>
                            {/* Progress Bar */}
                            {isLoading && (
                                <div className="progress-bar-container">
                                    <div className="progress-bar"></div>
                                </div>
                            )}

                            <div className="BtnDiv">
                                <button className="pers-pay-btn" onClick={saveUserandProceed}>
                                    {
                                        (renderFlag === "ticket") ? <span>Proceed to Pay</span>
                                            : <span>Register Yourself</span>
                                    }

                                </button>
                            </div>
                        </div>
                        <div className="pers-accept-div text-center">
                            {/* <input type="checkbox" id="terms-pers" /> */}
                            <label htmlFor="terms" className="text-terms-acc">
                                I accept the Terms of Use & Privacy Policy
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div className="personaldet-modal" id="mobileView">
                <div className="containerr h-100">
                    <div className="cont-pers">
                        <span onClick={onClose}>
                            <BsXCircle className="closebtn bg-white rounded-pill" />
                        </span>
                        <div>
                            <p className="text-center"><span className="book">Book </span><span className="front-seat fw-semibold"> Your Front Seat</span></p>
                            <p className="heading fw-semibold">Personal Details</p>
                            <form>
                                <div>
                                    <input
                                        className="form-control border-primary mb-3"
                                        type="text"
                                        id="first-name"
                                        placeholder="First Name"
                                        value={firstName}
                                        onChange={(e) => setFirstName(e.target.value)}
                                        style={{ height: "50px" }}
                                    />
                                </div>
                                <div>
                                    <input
                                        className="form-control border-primary mb-3"
                                        type="text"
                                        id="last-name"
                                        placeholder="Last Name"
                                        value={lastName}
                                        onChange={(e) => setLastName(e.target.value)}
                                        style={{ height: "50px" }}
                                    />
                                </div>
                                <div>
                                    <input
                                        className="form-control border-primary"
                                        type="text"
                                        id="email"
                                        placeholder="Email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        style={{ height: "50px" }}
                                    />
                                </div>
                                <div className="d-flex justify-content-between" style={{ marginTop: "29px", marginBottom: "23px" }}>
                                    <div>
                                        <input
                                            className="pers-selection"
                                            type="radio"
                                            id="female"
                                            name="gender"
                                            value="female"
                                            checked={gender === "female"}
                                            onChange={() => setGender("female")}
                                        />
                                        <label className="pers-gender" htmlFor="female">
                                            Female
                                        </label>
                                    </div>
                                    <div>
                                        <input
                                            className="pers-selection"
                                            type="radio"
                                            id="male"
                                            name="gender"
                                            value="male"
                                            checked={gender === "male"}
                                            onChange={() => setGender("male")}
                                        />
                                        <label className="pers-gender" htmlFor="male">
                                            Male
                                        </label>
                                    </div>
                                    <div>
                                        <input
                                            className="pers-selection"
                                            type="radio"
                                            id="other"
                                            name="gender"
                                            value="other"
                                            checked={gender === "other"}
                                            onChange={() => setGender("other")}
                                        />
                                        <label className="pers-gender" htmlFor="others">
                                            Others
                                        </label>
                                    </div>
                                </div>
                                <div className="row" style={{ marginBottom: "45px" }}>
                                    <DatePicker
                                        className="form-control border-primary ms-lg-2"
                                        selected={dob}
                                        id="dob"
                                        onChange={handleDateChange}
                                        placeholderText="DOB    DD/MM/YYYY"
                                        dateFormat="dd/MM/yyyy"
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                    />
                                </div>
                                <button className="pers-pay-btn" type="submit" onClick={saveUserandProceed}>
                                    {
                                        (renderFlag === "ticket") ? <span>Proceed to Pay</span>
                                            : <span>Register Yourself</span>
                                    }

                                </button>
                                <span className="pers-accept-div">
                                    {/* <input type="checkbox" id="terms-pers" /> */}
                                    <label htmlFor="terms" className="text-terms-acc">
                                        I accept the Terms of Use & Privacy Policy
                                    </label>
                                </span>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SignUpPage;
