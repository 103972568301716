import React from 'react'

function TermsCondition() {
  return (
    <div className='privacypolicy-width shadow-sm p-lg-5 p-3 m-auto'>
      <h5 className='fontstylePoppin'>Payment & Refund Policies.</h5>
      <p>
        When you make a payment, you agree to use a valid payment method. You agree to pay the price for tickets you purchase, and you authorize us to charge your debit or credit card or process other means of payment for those fees. Ants Media works with third-party payment processing partners to offer you the most convenient payment methods in your country and to keep your payment information secure.
      </p>
      <p>
        Customers must review details like name, date, time, event, and ticket quantity (s) before executing the purchase. Tickets, once booked, cannot be canceled, transferred, or refunded under any circumstances. All sales are final. Ants Media does not offer refunds, cancellations, or exchanges of tickets purchased on the site.
      </p>
      <p>
        Ants Media may cancel a ticket due to unforeseen circumstances, and in that case, customers will be offered a full refund within 7-10 working days. Ants Media will not be responsible for any individual expenses related to the visit (i.e., hotel, flight, car rental, etc.).
      </p>
      <p>
        Ants Media or our partners may offer gift and promotional codes to certain members. Certain codes may be redeemed for your purchase applied to your ticket account, which may be used to purchase tickets on our platform, subject to the terms included with your codes. These codes and credits and any promotional value linked to them may expire if not used within the period specified in your ticket account. Gift and promotional codes offered by Ants Media may not be refunded for cash unless otherwise specified in the terms included with your codes or as required by applicable law. Gift and promotional codes offered by a partner are subject to that partner’s refund policies.
      </p>
      <h6 className='fontstylePoppin'>General Terms & Conditions</h6>
      <ul>
        <li>
          <p>
            Carrying a valid ID proof along with you is mandatory.
          </p>
        </li>
        <li>
          <p>
            Weapons, knives, guns, fireworks, helmets, laser devices, bottles, and musical instruments, among other potentially hazardous items, will not be permitted in the venue and will be ejected with or without the owner.
          </p>
        </li>
        <li>
          <p>
            The sponsors/performers/organizers are not liable for any injuries or damages caused by the event.
          </p>
        </li>
        <li>
          <p>
            Inebriated people may be denied entry.
          </p>
        </li>
        <li>
          <p>
            The organizers reserve the right to refuse late entry to the event.
          </p>
        </li>
        <li>
          <p>
            Event-specific terms & conditions may apply at the organizer's discretion.
          </p>
        </li>
      </ul>
    </div>
  )
}

export default TermsCondition