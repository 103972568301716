import axios from 'axios';
import './SelectTickets.css';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { API_URL } from '../../config';
import { BsArrowLeft } from "react-icons/bs";
import { CiCircleMinus, CiCirclePlus } from 'react-icons/ci';
import PuffLoader from "react-spinners/PuffLoader";
import SyncLoader from 'react-spinners/SyncLoader';

function SelectTickets({ id }) {
    const user = JSON.parse(localStorage.getItem('userData'));
    const [ticketList, setTicketList] = useState([]);
    const [eventData, SetEventData] = useState([]);
    const [loading, setLoading] = useState(true); // Loading state added
    const [bookingQuantities, setBookingQuantities] = useState({});
    const [ticketbookedbyuser, setTicketbookedbyuser] = useState({});
    const [selectedTicket, setSelectedTicket] = useState(null);
    const Navigate = useNavigate();
    const location = useLocation();
    const [isProceeding, setIsProceeding] = useState(false);
    const [date_for_booked_event, setDateForBookedEvent] = useState("");

    useEffect(() => {
        if (location.state && location.state.date_for_booked_event) {
            setDateForBookedEvent(location.state.date_for_booked_event);
        } else {
            setDateForBookedEvent(eventData.event_starts_date);
        }
    }, [location.state, eventData.event_starts_date]);
   
    useEffect(() => {
        (async () => {
            try {
                setLoading(true); // Start loading
                const { data } = await axios.get(`${API_URL}/api/eventdetail/${id}`);
                setTicketList(data[0].ticket);
                SetEventData(data[0]);
                findUserExitMaxTickets(data[0].ticket, data[0]);
            } catch (error) {
                console.error("Error fetching ticket data:", error);
            } finally {
                setLoading(false); // End loading
            }
        })();
    }, [id]);

    const findUserExitMaxTickets = async (tickets, events) => {
        try {
            for (let i = 0; i < tickets.length; i++) {
                const ticketId = tickets[i]._id;
                
                // Fetch the booked ticket quantities for the specific ticket
                const response = await axios.get(
                    `${API_URL}/ticket/findusermaxticket/${user._id || user.uid}/${ticketId}/${events._id}`
                );
                
                // Sum up the bookedTicketQuantities from the response data
                const totalBookedQuantity = response.data.data.reduce((sum, entry) => {
                    return sum + (entry.bookedTicketQuantities || 0);
                }, 0);
                
                // Update the state with the total quantity for this ticket
                setTicketbookedbyuser((previous) => ({
                    ...previous,
                    [ticketId]: totalBookedQuantity
                }));
                console.error("setTicketbookedbyuser:", ticketbookedbyuser);
            }
        } catch (error) {
            console.error("Error in findUserExitMaxTickets:", error);
        }
    };
    
    

    const formatDate = (dateString) => {
        const dateObj = new Date(dateString);
        const day = String(dateObj.getDate()).padStart(2, '0');
        const monthNames = [
            "Jan", "Feb", "Mar", "Apr", "May", "June",
            "July", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];
        const month = monthNames[dateObj.getMonth()];
        const year = String(dateObj.getFullYear()).slice(-2);
        return `${day} ${month} '${year}`;
    };

    const formatTime = (timeString) => {
        if (timeString == null || timeString === undefined || timeString === '') {
            timeString = "00:00:00";
        }
        const dateObj = new Date(`1970-01-01T${timeString}Z`);
        let hours = dateObj.getUTCHours();
        const minutes = String(dateObj.getUTCMinutes()).padStart(2, '0');
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12;

        const formattedTime = `${hours}:${minutes} ${ampm}`;
        return formattedTime;
    };


    const incrementQuantity = (ticketId) => {
        setBookingQuantities((prevQuantities) => {
            const currentQuantity = prevQuantities[ticketId] || 0;
            const ticket = ticketList.find(ticket => ticket._id === ticketId);
    
            const maxBooking = ticket.max_booking;  // Maximum tickets the user can book
            const userBookedQuantity = ticketbookedbyuser[ticketId] || 0;  // Already booked tickets by the user
            const availableQuantity = ticket.isTicketForPerDay
                ? ticket.available_daily_ticket_quantities[date_for_booked_event] || 0
                : ticket.ticket_avability;
    
            // Only proceed with booking limits if max_booking is not zero
            if (maxBooking !== 0) {
                // Calculate how many more tickets the user can book
                const remainingTickets = maxBooking - userBookedQuantity;
    
                // Ensure that the user can book more tickets, and they don't exceed the remaining available or their max allowed
                if (currentQuantity < Math.min(availableQuantity, remainingTickets)) {
                    return { ...prevQuantities, [ticketId]: currentQuantity + 1 };
                } else {
                    alert(`You have already booked ${userBookedQuantity} tickets. You can only book up to ${remainingTickets} more tickets for this type.`);
                }
            } else {
                // If max_booking is 0, allow the user to book up to available tickets, as there's no limit
                if (currentQuantity < availableQuantity) {
                    return { ...prevQuantities, [ticketId]: currentQuantity + 1 };
                }
            }
    
            return prevQuantities;
        });
        setSelectedTicket(ticketId);
    };
    
    
    
    

    const decrementQuantity = (ticketId) => {
        setBookingQuantities((prevQuantities) => {
            const newQuantity = Math.max((prevQuantities[ticketId] || 0) - 1, 0);
            if (newQuantity === 0) {
                setSelectedTicket(null);
                return {};
            }
            return {
                [ticketId]: newQuantity,
            };
        });
    };

    const handleTicketBooking = async () => {
        setIsProceeding(true); // Start button loader
        try {
            const response = await axios.post(`${API_URL}/ticket/booktickets`, {
                bookingQuantities,
                date_for_booked_event
            });
            if (response.status === 200) {
                Navigate(`/eventbooking/${(eventData.event_name).replace(/\s+/g, '_')}/${eventData.unique_id}`, { state: { bookingQuantities } });
            }
        } catch (error) {
            console.error("Error booking tickets:", error);
        } finally {
            setIsProceeding(false); // Stop button loader
        }
    };


    const getTotalQuantity = () => {
        return Object.values(bookingQuantities).reduce((total, quantity) => total + quantity, 0);
    };

    const getTotalPrice = () => {
        return ticketList.reduce((total, ticket) => {
            const quantity = bookingQuantities[ticket._id] || 0;
            return total + (quantity * ticket.ticket_price);
        }, 0);
    };

    const isButtonDisabled = getTotalQuantity() === 0;

    return (
        <>
            <div className='ticketlist'>
                <div className='row' id="largedeviceview">
                    <div className='col-md-1 col-sm-1'>
                        <span className='backarrowlink link-dark mt-2' onClick={() => Navigate(-1)}><BsArrowLeft size={30} /></span>
                    </div>
                    <div className='col-md-11 col-sm-11'>
                        <div className='d-flex flex-column justify-content-center'>
                            <span className='event-name'>{eventData.event_name}</span>
                            <span className='event-detail'>{eventData.event_venue ? eventData.event_venue + "," : ''} {eventData.event_city} |
                            &nbsp; {formatDate(date_for_booked_event) ? formatDate(date_for_booked_event) : formatDate(eventData.event_starts_date)}
                            </span>
                        </div>
                    </div>
                </div>
                <div id="smalldeviceview">
                    <div className="row border-bottom pb-2">
                        <div className="col-1 d-flex justify-content-center align-items-center">
                            <span className='backarrow link-dark' onClick={() => Navigate(-1)}><BsArrowLeft size={24} /></span>
                        </div>
                        <div className='col-11 d-flex flex-column justify-content-center align-items-center'>
                            <span className='event-name fw-semibold'>{eventData.event_name}</span>
                            <span className='event-detail'>
                                {formatDate(date_for_booked_event) ? formatDate(date_for_booked_event) : formatDate(eventData.event_starts_date)}  &nbsp;
                                {/* {formatDate(eventData.event_starts_date)} {eventData.event_ends_date && (eventData.event_starts_date !== eventData.event_ends_date)  ? ` - ${formatDate(eventData.event_ends_date)}` : ''}  {formatTime(eventData?.event_starts_Time)} */}
                                {/* {eventData.event_venue ? eventData.event_venue + "," : ''}  */}
                                {eventData.event_city}
                            </span>
                        </div>
                    </div>
                </div>
                {/* Loader */}
                {loading ? (
                    <div className='d-flex justify-content-center align-items-center' style={{ height: "230px" }}>
                        <PuffLoader size={28} loading={loading} />
                    </div>
                ) : (
                    <>
                        <div className='head'>Select Your Front Seat</div>
                        <div style={{ marginBottom: "10%" }}>
                            {(() => {
                                const currentDate = new Date();
                                const filteredTickets = ticketList.filter(ticket => {
                                    if (!ticket.sale_start && !ticket.sale_end) {
                                        return true;
                                    }
                                    const saleStartDate = new Date(ticket.sale_start);
                                    const saleEndDate = new Date(ticket.sale_end);
                                    return currentDate >= saleStartDate && currentDate <= saleEndDate;
                                });
                                return filteredTickets.length > 0 ? (
                                    filteredTickets.map((ticket) => (
                                        <div className='listoftickets rounded' key={ticket._id}>
                                            <div className='ticketDetails'>
                                                <span className='ticketname'>{ticket.ticket_Name}</span>
                                                <span className='ticketdescription'>{ticket.ticket_description}</span>
                                            </div>
                                            <div>
                                                <span className='ticketname ms-3'> ₹ {ticket.ticket_price}</span>
                                                <span className="d-flex pt-1">
                                                    {selectedTicket !== ticket._id ? (
                                                        <>
                                                            {ticket.isTicketForPerDay && ticket.available_daily_ticket_quantities[date_for_booked_event] === 0 ? (
                                                                <button className="add-btn">Sold out</button>
                                                            ) : (
                                                                (bookingQuantities[ticket._id] || 0) < (ticket.isTicketForPerDay
                                                                    ? ticket.available_daily_ticket_quantities[date_for_booked_event]
                                                                    : ticket.ticket_avability
                                                                ) ? (
                                                                    <button
                                                                        className="add-btn"
                                                                        onClick={() => incrementQuantity(ticket._id)}
                                                                        disabled={false}
                                                                    >
                                                                        Add
                                                                    </button>
                                                                ) : (
                                                                    <button className="add-btn" disabled>Sold out</button>
                                                                )
                                                            )}
                                                        </>
                                                    ) : (
                                                        <>
                                                            <CiCircleMinus className='couting' onClick={() => decrementQuantity(ticket._id)} />{" "}
                                                            <span className="ticketcount mx-2"> {bookingQuantities[ticket._id] || 0} </span>
                                                            <CiCirclePlus
                                                                className={`couting text-primary ${(bookingQuantities[ticket._id] || 0) >= (ticket.isTicketForPerDay
                                                                    ? ticket.available_daily_ticket_quantities[date_for_booked_event]
                                                                    : ticket.ticket_avability
                                                                ) ? 'disabled' : ''}`}
                                                                onClick={() => incrementQuantity(ticket._id)}
                                                            />{" "}
                                                        </>
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <p>Coming Soon</p>
                                );
                            })()}
                        </div>
                    </>
                )}
            </div>

            {/* Footer Part */}
            <div className='footerPart py-3' id="largedeviceview">
                <div className='footer-content'>
                    <div className='d-flex flex-column'>
                        <span className='ticketPrice fw-semibold'>₹{getTotalPrice()}</span>
                        <span className='totalCount'>{getTotalQuantity()} seats</span>
                    </div>
                    <button
                        className={`proceedbtn ${isButtonDisabled || isProceeding ? "btnisDisabled" : "btnisEabled"}`}
                        disabled={isButtonDisabled || isProceeding}
                        onClick={handleTicketBooking}
                    >
                        {isProceeding ? (
                            <SyncLoader animation="border" color="#FFFF" size="7" speedMultiplier={1} margin={4} />
                        ) : (
                            "Proceed"
                        )}
                    </button>
                </div>
            </div>

            <div className='smallfooterPart py-2' id="smalldeviceview">
                <div className='footer-content px-3'>
                    <div className='d-flex flex-column'>
                        <span className='ticketPrice fw-semibold mb-2'>₹{getTotalPrice()}</span>
                        <span className='totalCount'>{getTotalQuantity()} seats</span>
                    </div>
                    <button
                        className={`proceedbtn ${isButtonDisabled || isProceeding ? "btnisDisabled" : "btnisEabled"}`}
                        disabled={isButtonDisabled || isProceeding}
                        onClick={handleTicketBooking}
                    >
                        {isProceeding ? (
                            <SyncLoader animation="border" color="#FFFF" size="7" speedMultiplier={1} margin={4} />
                        ) : (
                            "Proceed"
                        )}
                    </button>
                </div>
            </div>
        </>
    );
}

export default SelectTickets;

