import React, { useEffect, useState } from 'react'
import './TicketBooking.css'
import org_Logo from '../../Assets/logo_224x61-02.png'
import My_Front_Seat_Logo from '../../Assets/My Front Seat Logo.png'
import { useNavigate } from 'react-router-dom';
import { API_URL } from '../../config';
import { SlArrowDown } from 'react-icons/sl';
import { BsArrowLeft } from 'react-icons/bs';
import axios from 'axios';
import ReduceText from '../Interface';
import LoginModal from './LoginModal';
import SyncLoader from "react-spinners/SyncLoader";

function TicketBooking({ ticket, eventDetail, ticketids, onLogin }) {
  // const [selectedOption, setSelectedOption] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const user = JSON.parse(localStorage.getItem('userData'));
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  let [billing, SetBilling] = useState({
    ticketQuantity: 0,
    subTotal: 0,
    bookingFee: 0,
    gst: 0,
    basic_price: 0,
    total_amount: 0
  });


  // const handleCheckboxChange = (option) => {
  //   setSelectedOption(option);
  // };

  // const toggleDropdown = () => {
  //   setDropdownOpen(!dropdownOpen);
  // };

  const formatTime = (timeString) => {
    if (timeString == null || timeString === undefined || timeString === '') {
      timeString = "00:00:00";
    }

    const dateObj = new Date(`1970-01-01T${timeString}Z`);
    let hours = dateObj.getUTCHours();
    const minutes = String(dateObj.getUTCMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12;

    const formattedTime = `${hours}:${minutes} ${ampm}`;
    return formattedTime;
  };

  const formatDate = (dateString) => {
    const dateObj = new Date(dateString);
    const day = String(dateObj.getDate()).padStart(2, '0');
    const monthNames = [
      "Jan", "Feb", "Mar", "Apr", "May", "June",
      "July", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    const month = monthNames[dateObj.getMonth()]; // Get month name
    const year = String(dateObj.getFullYear()).slice(-2); // Get last two digits of the year
    return `${day} ${month} '${year}`;
  };

  const handleBilling = () => {
    let count = 0;
    let amount = 0;
    let basic_rate = 1;

    count = ticket.bookingQuantities;
    basic_rate = (basic_rate * ticket.basic_price) * ticket.bookingQuantities;
    amount = ticket.bookingQuantities * ticket.ticket_price;

    console.log("::::AMOUNT::::", amount)
    // for (let i = 0; i < ticket.length; i++) {
    //   count = count + ticket[i].bookingQuantities;
    //   basic_rate = basic_rate + ticket[i].basic_price;
    //   amount = amount + (ticket[i].bookingQuantities * ticket[i].ticket_price);
    // }
    const gst9 = (Math.ceil(basic_rate * 0.09 * 100) / 100).toFixed(2);
    const fee = Number(basic_rate) + (2 * Number(gst9));
    const totalAmount = amount + fee;



    SetBilling({
      ticketQuantity: count,
      subTotal: amount,
      bookingFee: fee,
      basic_price: basic_rate,
      gst: Number(gst9),
      // total_amount: Number(totalAmount.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })),
      total_amount: Number(totalAmount),
    });
  };

  useEffect(() => {
    console.log("TICKET: ", ticket, billing)
    if (ticket) {
      handleBilling();
    }
  }, [ticket, eventDetail]);


  //  Payment Process
  const [orderId, setOrderId] = useState("");

  const LoadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => { resolve(true) };
      script.onerror = () => { resolve(false) };
      document.body.appendChild(script);
    })
  }
  const CreateOrder = async () => {
    // apply check here to  validate ticket avaibility
    console.log("ticketids : ", ticketids)
    const amt = billing.total_amount * 100;
    let data = JSON.stringify({
      amount: amt,
      currency: "INR",
      user_id: user._id,
      ticketDetails: ticketids,
      eventDetails: eventDetail,
      quantity: billing.ticketQuantity,
      eventreserveDate: ticket.reserveDate
    });

    let config = {
      method: "POST",
      maxBodyLength: Infinity,
      url: `${API_URL}/pay/payment-order`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data
    }
    // api call
    const res = await axios.request(config)
    handleRazorpayScreen(res.data.amount, res.data.order_id)
  }

  const handleRazorpayScreen = async (amount, order_id) => {
    const res = await LoadScript("https://checkout.razorpay.com/v1/checkout.js");
    if (!res) {
      alert("Some error at razorpay");
      return;
    }
    const options = {
      "key": "rzp_live_QQmdUNR3XVKX87",
      "amount": amount,
      "currency": "INR",
      "name": "My Front Seat",
      "description": "Payment to My-Front-Seat",
      "order_id": order_id,
      "image": My_Front_Seat_Logo,
      "handler": function (response) {
        setOrderId(order_id);
        CheckPayment(order_id);
      },
      "prefill": {
        "name": "My Front Seat",
        "email": "myfrontseatin@gmail.com"
      },
      "theme": {
        "color": "#04092C"
      }
    }
    const PaymentObject = new window.Razorpay(options);
    PaymentObject.open();
  }

  const CheckPayment = async (order_id) => {
    const response = await axios.get(`${API_URL}/pay/payment/status/${order_id}`);
    if (response.data && response.status===200) {
      alert("Payment successful!");
      await axios.post(`${API_URL}/ticket/sendmail`, {
        orderId : order_id,
        email: user.email,
        event_name: eventDetail.event_name,
        userName: (user.firstName + user.lastName),
        ticket_link: "https://www.myfrontseat.in/yourtickets",
        ticketName: ticket.ticketName,
        bookingfee : billing.bookingFee,
        basicRate : ticket.basic_price,
        gst: billing.gst,
        totalBasicPrice: billing.basic_price,
        cover_image: eventDetail.cover_image,
        phone_no: user.phone,
        event_date: formatDate(eventDetail.event_starts_date),
        event_time: formatTime(eventDetail.event_starts_Time),
        event_venue: eventDetail.event_venue + "," + eventDetail.event_city
      })
      navigate(`/ticketbooked/${(eventDetail.event_name).replace(/\s+/g, '_')}`);
    } else {
      alert("Unable to process your payment");
    }
  }

  const [showLoginModal, setShowLoginModal] = useState(false);

  const handleClose = () => setShowLoginModal(false);
  const handleShow = () => setShowLoginModal(true);

  const handleProceedToPay = async () => {
    setLoading(true);  // Show loader
    if (localStorage.getItem("userData")) {
      await CreateOrder();
    } else {
      handleShow();
    }
    setLoading(false);  // Hide loader after process completes
  };

  // function to store non-payable tickets (Free Tickets)
  const handleSaveData = async () => {
    const body = {
      amount: billing.total_amount,
      user_id: user._id || user.uid,
      ticketDetails: ticketids,
      eventDetails: eventDetail,
      quantity: billing.ticketQuantity,
      eventreserveDate: ticket.reserveDate
    }

    const saveTicketResponse = await axios.post(`${API_URL}/ticket/bookfreetickets`, body)
    if (saveTicketResponse.status === 200) {
      await axios.post(`${API_URL}/ticket/sendmail`, {
        orderId : saveTicketResponse.data.freeTicketBooking.order_id,
        email: user.email,
        event_name: eventDetail.event_name,
        userName: (user.firstName + user.lastName),
        ticket_link: "https://www.myfrontseat.in/yourtickets",
        ticketName: ticket.ticketName,
        bookingfee : billing.bookingFee,
        basicRate : ticket.basic_price,
        gst: billing.gst,
        totalBasicPrice: billing.basic_price,
        cover_image: eventDetail.cover_image,
        phone_no: user.phone,
        event_date: formatDate(eventDetail.event_starts_date),
        event_time: formatTime(eventDetail.event_starts_Time),
        event_venue: eventDetail.event_venue + "," + eventDetail.event_city
      })
    } else {
      alert("Failed to book tickets");
    }
  }
  const handleGetTicket = () => {
    setLoading(true);
    if (localStorage.getItem("userData")) {
      handleSaveData();
      navigate(`/ticketbooked/${(eventDetail.event_name).replace(/\s+/g, '_')}`);
    } else {
      handleShow();
    }
    setLoading(false);
  }

  const handleLoginSuccess = () => {
    onLogin(user);
    setShowLoginModal(false);
    // setIsLoggedIn(true); // Update the login state
  };

  return (
    <>
      {/* Show LocationModal */}
      {showLoginModal && (
        <LoginModal
          showModal={showLoginModal}
          onLoginSuccess={handleLoginSuccess}
        />
      )}

      <div className='bookingticketmainDiv' id="largeScreenViewPort">
        <div className='bookingticket'>
          <div className='row'>
            <div className='col-md-1 col-sm-1 col-1'>
              <span className='backarrowlink link-dark me-md-5 me-1 mt-2' data-bs-toggle="modal" data-bs-target="#exampleModal"><BsArrowLeft size={30} /></span>
            </div>
            <div className='col-md-11 col-sm-11 col-11'>
              <div className='headingdiv'>
                <span className='heading'>{eventDetail.event_name}</span>
                <span className='subheading'>
                  {eventDetail.event_venue} {eventDetail.event_city} | {formatDate(ticket.reserveDate)? formatDate(ticket.reserveDate) : formatDate(eventDetail.event_starts_date)} | {formatTime(eventDetail.event_starts_Time)}</span>
              </div>
            </div>
          </div>
          <div className='row'>
            <p className='bookinghead'>Booking Summary</p>
            <div className='col-lg-6 col-md-6 col-sm-6'>
              <div className='eventimg'>
                <img
                  className="booking-img rounded-4 w-100 h-100"
                  src={`${API_URL}/uploads/${eventDetail.cover_image}`}
                  alt="booking"
                />
                <span className='ticketname'>
                  {/* {
                    ticket && ticket.length > 0 ? (
                      ticket.map((tic, index) => (
                        <span key={tic._id}>
                          {
                            tic.bookingQuantities > 0 &&
                            (
                              <>
                                {index > 0 && ","} {tic.ticket_Name}
                              </>
                            )
                          }
                        </span>
                      ))
                    ) : (
                      ""
                    )
                  } */}
                  <span>
                    {ticket.ticket_Name}
                  </span>
                </span><br />
                <span className='quantity'>{billing.ticketQuantity} Tickets</span>
              </div>
            </div>
            <div className='col-lg-6 col-md-6 col-sm-6'>
              <div className='bookingbifargation'>
                <div className="d-flex justify-content-between">
                  <span className='summaryValue'>Sub-total</span>
                  <span className='summaryValue'>₹ {billing.subTotal.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                </div>
                <div>
                  <div className="d-flex justify-content-between border-bottom border-secondary-subtle pb-1" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                    <span className='summaryValue'>Convenience Fee <SlArrowDown className='arrowdown ms-3' /></span>
                    <span className='summaryValue'>₹ {billing.bookingFee.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                  </div>
                  {/* Collapse div */}
                  <div className="collapse w-100 border-bottom border-secondary-subtle mt-1" id="collapseExample">
                    <div className="d-flex justify-content-between mb-1">
                      <span className='summaryValue'>Base Price</span>
                      <span className='summaryValue'>₹ {Number(billing.basic_price).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                    </div>
                    <div className="d-flex justify-content-between mb-1">
                      <span className='summaryValue'>Central GST(CGST) @9%</span>
                      <span className='summaryValue'>₹ {Number(billing.gst).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                    </div>
                    <div className="d-flex justify-content-between">
                      <span className='summaryValue'>State GST(SGST) @9%</span>
                      <span className='summaryValue'>₹ {Number(billing.gst).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                    </div>
                  </div>
                </div>
                <div className="mt-2 d-flex align-items-end justify-content-between ">
                  <span className="summaryValue fw-bold">Total Amount</span>
                  <span className="summaryValue fw-bold">₹ {Number(billing.total_amount).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-6 d-flex flex-column">
            </div>
            <div className="col-6">
              <div className="have-discount">Have a discount code?</div>
              <div className="d-flex">
                <input
                  type="text"
                  className="code w-100"
                  placeholder="Enter code"
                />
                <div className="apply">Apply</div>
              </div>
            </div>
          </div>
        </div>

        <div className="footerPart proceedpay-div">
          {
            ticket.ticket_type === "free" ?
              <button className="proceedpay-btn" onClick={handleGetTicket} disabled={loading}>
                {loading ? <SyncLoader animation="border" color="#FFFF" size="7" speedMultiplier={1} margin={4} /> : "Get your Ticket"}</button> :
              <button className="proceedpay-btn" onClick={handleProceedToPay} disabled={loading}>
                {loading ? <SyncLoader animation="border" color="#FFFF" size="7" speedMultiplier={1} margin={4} /> : "Proceed to Pay"}</button>
          }

        </div>
      </div>

      <div id="smallScreenViewPort">
        <div className="row headerpart border-bottom">
          <div className="col-1 d-flex justify-content-center align-items-center">
            <span className='backarrow link-dark'data-bs-toggle="modal" data-bs-target="#exampleModal"><BsArrowLeft size={24} /></span>
          </div>
          <div className='col-11 d-flex justify-content-center align-items-center'>
            <span className='topheading fw-semibold'>Confirm Your Details</span>
          </div>
        </div>
        <div className='ticketbody'>
          <p className='summaryheading fw-semibold'>Booking Summary</p>
        </div>
        <div className='ticket-Details mt-0 card mx-3'>
          <div className='ticketNAme'>
            <span>
              {ReduceText(eventDetail?.event_name || '')}
            </span>
            <span className='ticketprice'>
              ₹ {billing.subTotal.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
            </span>
          </div>
          <hr />
          <div className='ticketdatetime'>
            <span> {formatDate(eventDetail.event_starts_date)}  {formatTime(eventDetail.event_starts_Time)}</span>
            <span>{eventDetail.event_city} </span>
          </div>
          <hr />
          <div className='d-flex justify-content-between'>
            <span className='ticketNAme'>
              {/* {
                ticket && ticket.length > 0 ? (
                  ticket.map((tic, index) => (
                    <span key={tic._id}>
                      {index > 0 ? "," : ''} {tic.ticket_Name}
                    </span>
                  ))
                ) : (
                  ""
                )
              } */}

              <span>
                {ticket.ticket_Name}
              </span>
            </span>
            <span className='quantity'>{billing.ticketQuantity} Tickets</span>
          </div>
        </div>
        <div className=" mt-3 mx-3">
          <div className="ticketname mb-2">Have a discount code?</div>
          <div className="d-flex">
            <input
              type="text"
              className="code w-100"
              placeholder="Enter code"
            />
            <div className="apply">Apply</div>
          </div>
        </div>
        <div className='bookingDetails card mx-3'>
          <div className="d-flex justify-content-between">
            <p className='ticketprice'>Sub-total</p>
            <p className='ticketprice'>₹ {billing.subTotal.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
          </div>
          <div>
            <div className="d-flex justify-content-between" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
              <p className='ticketprice'>Convenience Fee <SlArrowDown className='arrowdown ms-3' /></p>
              <p className='ticketprice'>₹ {billing.bookingFee.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
            </div>
            {/* Collapse div */}
            <div className="collapse w-100 border-top border-bottom border-secondary-subtle mt-1" id="collapseExample">
              <div className="d-flex justify-content-between mb-1">
                <span className='ticketprice fw-normal'>Base Price</span>
                <span className='ticketprice fw-normal'>₹ {Number(billing.basic_price).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
              </div>
              <div className="d-flex justify-content-between mb-1">
                <span className='ticketprice fw-normal'>Central GST(CGST) @9%</span>
                <span className='ticketprice fw-normal'>₹ {Number(billing.gst).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
              </div>
              <div className="d-flex justify-content-between">
                <span className='ticketprice fw-normal'>State GST(SGST) @9%</span>
                <span className='ticketprice fw-normal'>₹ {Number(billing.gst).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between mt-2">
            <p className="total">Total Amount</p>
            <p className="total">₹ {billing.total_amount.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
          </div>
        </div>
        <div className='smallfooterPart py-2'>
          <div className='text-center'>
            {
              ticket.ticket_type === "free" ?
                <button className="proceedpay-btn" onClick={handleGetTicket} disabled={loading}>
                  {loading ? <SyncLoader animation="border" color="#FFFF" size="7" speedMultiplier={1} margin={4} /> : "Get your Ticket"}</button> :
                <button className="proceedpay-btn" onClick={handleProceedToPay} disabled={loading}>
                  {loading ? <SyncLoader animation="border" color="#FFFF" size="7" speedMultiplier={1} margin={4} /> : "Proceed to Pay"}</button>
            }
          </div>
        </div>
      </div>

      {/* backto Product page confirmation modal */}
      {/* <!-- Modal --> */}
      <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <span>Are you sure you want to exit?</span>
            </div>
            <div className="modal-footer d-flex justify-content-center">
              <button type="button" className="btn secondary_background" data-bs-dismiss="modal">Cancel</button>
              <button type="button" className="btn primary_background" onClick={() => navigate(`/${eventDetail.event_city}/${eventDetail.event_name.replace(/\s+/g, '_')}/${eventDetail.unique_id}`)} data-bs-dismiss="modal">Yes</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TicketBooking