import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { API_URL } from '../../config';
import LocationModal from '../Components/LocationModal';
import NavBar from '../Components/NavBar';
import Footer from '../Components/Footer';
import axios from 'axios';
import ProductDetail from '../Components/ProductDetail';
import PuffLoader from "react-spinners/PuffLoader";
function ProductPage() {
    const { id } = useParams();
    const [eventDetail, setEventDetail] = useState(null);
    const [userLocation, setUserLocation] = useState('Nagpur');
    const [showLocationModal, setShowLocationModal] = useState(false);
    const [isLoading, setIsLoading] = useState(true); // New state for loading

    // Function to handle location change
    const handleLocationChange = (location) => {
        setUserLocation(location);
        setShowLocationModal(false); // Close modal after location change
    };

    // Function to handle closing the location modal
    const handleCloseLocationModal = () => {
        setShowLocationModal(false);
    };

    const getEventDetail = async () => {
        try {
            const event = await axios.get(`${API_URL}/api/eventdetail/${id}`);
            setEventDetail(event.data);
        } catch (error) {
            console.error('Failed to fetch event:', error);
        } finally {
            setIsLoading(false); // Once data is fetched, set loading to false
        }
    };

    useEffect(() => {
        getEventDetail();
    }, [id]);

    useEffect(() => {
        // Scroll to top when the component loads or data is done loading
        if (!isLoading) {
            window.scrollTo({
                top: 0,
                behavior: "smooth", // Adds smooth scrolling
            });
        }
    }, [isLoading]);

    return (
        <div>
            <NavBar userLocation={userLocation} onNext={() => setShowLocationModal(true)} />

            {/* Display loading indicator if data is being fetched */}
            {isLoading ? (
                <div className='loaderShowing d-flex justify-content-center align-items-center' style={{height:"100vh"}} >
                    <PuffLoader size={28}
                    loading = {isLoading}/>
                </div>
            ) : (
                eventDetail && (
                    <>
                        {/* Render Location component as a modal */}
                        {showLocationModal && (
                            <LocationModal
                                onClose={() => setShowLocationModal(false)}
                                onLocationChange={handleLocationChange}
                            />
                        )}
                        {/* Product Detail */}
                        <ProductDetail eventDetail={eventDetail} />
                    </>
                )
            )}

            <div className="smallscreen">
            <Footer/>
            </div>
        </div>
    );
}

export default ProductPage;
