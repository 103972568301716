const CategoryImages = [
    // {
    //     id: 1,
    //     image : '/images/Navaratri.png',
    //     category: "Navaratri"
    // },
    {
        id: 2,
        image : '/images/Live_Music.png',
        category: "Live Music"
    },
    {
        id: 3,
        image : '/images/Experiences.png',
        category: "Experiences"
    },
    {
        id: 4,
        image : '/images/Conferences.png',
        category: "Conferences"
    },
    {
        id: 5,
        image : '/images/Workshops.png',
        category: "Workshops"
    },{
        id : 6,
        image : '/images/Comedy.png',
        category: "Comedy"
    }
];

export default CategoryImages;