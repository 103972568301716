import React, { useEffect, useState } from 'react';
import './Trendings.css';
import axios from 'axios';
import { API_URL } from '../../config';
import { Link } from 'react-router-dom';

function Trending({ userLocation }) {
    const [trendingEvent, SetTrendingEvent] = useState([]);
    const Month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const getEventsByCity = async () => {
        try {
            const receivedevent = await axios.get(`${API_URL}/api/events/${userLocation}`);
            const sortedData = receivedevent.data.sort((a, b) => 
                new Date(b.event_starts_date) - new Date(a.event_starts_date)
            );
            console.log(sortedData)
            SetTrendingEvent(sortedData);
        } catch (error) {
            alert('Error fetching images');
        }
    }

    useEffect(() => {
        getEventsByCity();
    }, [userLocation]);

    // Function to find the lowest price
    const lowestTicket = (ticketprices) => {
        let lowestPrice = ticketprices[0].ticket_price;
        for (let i = 1; i < ticketprices.length; i++) {
            if (ticketprices[i].ticket_price !== 0 && lowestPrice > ticketprices[i].ticket_price) {
                lowestPrice = ticketprices[i].ticket_price;
            }
        }
        return lowestPrice;
    }

    // Function to extract month from date string
    const getMonthFromDateString = (dateString) => {
        const date = new Date(dateString);
        return date.getMonth();
    };

    // Function to extract day from date string
    const getDayFromDateString = (dateString) => {
        const date = new Date(dateString);
        return date.getDate().toString().padStart(2, '0');
    };

    // Adjust card heights to match the tallest card
    useEffect(() => {
        const cards = document.querySelectorAll('.trendingCard');
        let maxHeight = 0;

        cards.forEach(card => {
            const cardHeight = card.offsetHeight;
            if (cardHeight > maxHeight) {
                maxHeight = cardHeight;
            }
        });

        cards.forEach(card => {
            card.style.height = `${maxHeight}px`;
        });
    }, [trendingEvent]);

    return (
        <>
            <div className='trendingeventDiv'>
                <h4 className='trendingHeading'>Trending Events {userLocation ? `in ${userLocation}` : ''}</h4>
                <hr className='horizontalLine' />
                <div className='row trendingeventlist'>
                    {trendingEvent.length > 0 ? (
                        trendingEvent.map((event, index) => (
                            <div className='col-lg-4 col-sm-6 col-12' key={index}>
                                <Link className='link' to={`/${event.event_city}/${(event.event_name).replace(/\s+/g, '_')}/${event.unique_id}`}>
                                    <div className='card trendingCard shadow-sm'>
                                        <div className='card-img-top trendingcardImg'>
                                            <img src={`${API_URL}/uploads/${event?.cover_image}`} className='trendingImg' alt="Event" />
                                        </div>
                                        {
                                            (event.ticket.every(tic => tic.ticket_avability === 0)) ? // Note the change here from "ticket" to "tickets"
                                                <div className='statusAlert fw-semibold'>Sold Out</div> :
                                                (new Date(new Date(event.event_ends_date).setHours(0, 0, 0, 0)) < new Date(new Date().setHours(0, 0, 0, 0))) ?
                                                    <div className='statusAlert fw-semibold'>Sold Out</div> :
                                                    <div className='statusAlert bg-success text-white fw-semibold'>Selling Fast</div>
                                        }
                                        <div className='card-body trendingcardContent d-flex align-items-center px-lg-4 px-md-2 px-sm-1 px-1'>
                                            <div className='d-flex flex-column text-center me-lg-4 me-sm-2 me-4 ms-3'>
                                                <span className='month text-primary fw-bold'>{Month[getMonthFromDateString(event?.event_starts_date)]}</span>
                                                <span className='date fw-bold'>{getDayFromDateString(event?.event_starts_date)}</span>
                                            </div>
                                            <div className='d-flex flex-column'>
                                                <span className='eventhead fw-bold'>{event?.event_name}</span>
                                                <span className='eventvenue text-secondary fw-normal'>{event?.event_venue} {event?.event_city}</span>
                                                <span className='eventprice text-secondary fw-normal'>₹ {event.ticket.length > 0 ? lowestTicket(event.ticket) : "0"} onwards</span>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        ))
                    ) : (
                        <p className='text-center fw-bold'>No trending events currently</p>
                    )}
                </div>
            </div>
        </>
    );
}

export default Trending;
