import React, { useEffect, useReducer, useState } from "react";
import './YourTicketPage.css'
import Ticket from '../../Assets/ticket.png'
import UserSideBar from "../Components/userSideBar";
import NavBar from "../Components/NavBar";
import LocationModal from "../Components/LocationModal";
import { QRCodeSVG } from 'qrcode.react';
import { API_URL } from "../../config";
import axios from "axios";
import { Loader } from 'react-feather';
import { Link, useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";


// Initial state for the reducer
const initialState = {
    bookedevent: []
};

// Reducer function to manage ticket and event detail state
const reducer = (state, action) => {
    switch (action.type) {
        case 'SET_RESPONSE_DATA':
            return {
                ...state,
                bookedevent: action.payload.bookedevent
            };
        default:
            return state;
    }
};

const YourticketsPage = () => {
    const [userLocation, setUserLocation] = useState('Nagpur');
    const [showLocationModal, setShowLocationModal] = useState(false);
    const [state, dispatch] = useReducer(reducer, initialState);
    const [showAllTickets, setShowAllTickets] = useState(false);
    const Navigate = useNavigate();

    // Function to handle location change
    const handleLocationChange = (location) => {
        setUserLocation(location);
        setShowLocationModal(false); // Close modal after location change
    };

    // Function to handle closing the location modal
    const handleCloseLocationModal = () => {
        setShowLocationModal(false);
    };

    // geting all booked tickets
    const user = JSON.parse(localStorage.getItem('userData'));
    const bookedTickets = async () => {
        const user_id = user._id;
        try {
            const ticketResponse = await axios.get(`${API_URL}/pay/ticket/${user_id}`);
            if (ticketResponse.status === 200) {
                let Data = ticketResponse.data.data;
                // Sort by created_at field (newest to oldest)
                const sortedData = Data.sort((a, b) => b.createdAt - a.createdAt);
                dispatch({
                    type: 'SET_RESPONSE_DATA',
                    payload: {
                        bookedevent: sortedData
                    },
                });
            }
            console.log("ORDER DATA saved: ", state.bookedevent)
        }
        catch (error) {
            alert("No data available!");
        }
    }


    const formatDate = (dateString) => {
        const dateObj = new Date(dateString);
        const day = String(dateObj.getDate()).padStart(2, '0');
        const monthNames = [
            "Jan", "Feb", "Mar", "Apr", "May", "June",
            "July", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];
        const month = monthNames[dateObj.getMonth()];
        const year = String(dateObj.getFullYear()).slice(-2);
        return `${day}-${month}-${year}`;
    };

    const formatTimespanDate = (timestamp) => {
        const date = new Date(timestamp);

        const options = {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
        };

        return date.toLocaleString('en-US', options);
    }

    useEffect(() => {
        bookedTickets();
    }, []);
    return (
        <>
            <div className='profilepage'>
                {/* Show LocationModal */}
                {showLocationModal && (
                    <LocationModal
                        onClose={handleCloseLocationModal}
                        onLocationChange={handleLocationChange}
                    />
                )}
                <NavBar
                    userLocation={userLocation}
                    onNext={() => setShowLocationModal(true)} />
                <div className='row' id="LargeScreenView">
                    <UserSideBar />
                    <div className='col-lg-10 col-md-10 col-sm-8 col-9'>
                        <div className='lists'>
                            <div className='d-flex justify-content-between border-bottom border-primary' style={{ marginTop: "34px" }}>
                                <span className='profileheading fw-semibold'>Your Front Seats</span>
                            </div>
                            <Link to='/' className="back-home"><IoIosArrowBack /> Back to Home page</Link>
                            <div className="orderedTicket">
                                {
                                    !state.bookedevent ? (
                                        <div><Loader /></div>
                                    ) : (
                                        state.bookedevent.length > 0 ? (
                                            <>
                                                {
                                                    // loop for booked event
                                                    state.bookedevent.map((bookedticket, index) => {
                                                        // Display only the latest ticket (index 0) initially, and more tickets when showAllTickets is true
                                                        if (!showAllTickets && index > 0) return null;
                                                        return (
                                                            <div key={bookedticket._id}>
                                                                {
                                                                    bookedticket.tickets.map((ticket) => {
                                                                        return (
                                                                            <>
                                                                                {
                                                                                    ticket.ticket_type === 'paid' ? (bookedticket.isPaid ?
                                                                                        <div className="row ticket" key={ticket._id}>
                                                                                            <div className="col-lg-9 col-md-9 height">
                                                                                                <div className="ticket-img">
                                                                                                    <img src={Ticket} className="w-100 h-100" />
                                                                                                </div>
                                                                                                <div className="overlayOnImg">                                                                                                    <div className="overlayeventImg">
                                                                                                    <img src={`${API_URL}/uploads/${bookedticket.eventDetails?.cover_image}`} className="w-100 h-100" />
                                                                                                </div>
                                                                                                    <div className="overlayeventDetail">
                                                                                                        <div className="d-flex flex-column" style={{ height: "5.5rem" }}>
                                                                                                            <span className="DMSans22">
                                                                                                                {bookedticket.eventDetails?.event_name}
                                                                                                            </span>
                                                                                                            <span className="ticketName">
                                                                                                                {bookedticket.bookedTicketQuantities} x {ticket.ticket_Name}
                                                                                                            </span>
                                                                                                        </div>
                                                                                                        <div className="d-flex flex-column" style={{ borderBottom: "1.3px dashed #98999B" }}>
                                                                                                            <div className="row mt-3">
                                                                                                                <div className="col-lg-6 col-md-6 col-sm-6 d-flex flex-column">
                                                                                                                    <span className="poppins15">Venue</span>
                                                                                                                    <span className="poppins11">
                                                                                                                        {bookedticket.eventDetails?.event_venue} {bookedticket.eventDetails?.event_city}
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                                <div className="col-lg-3 col-md-3 col-sm-3 d-flex flex-column">
                                                                                                                    <span className="poppins15">Time</span>
                                                                                                                    <span className="poppins11">
                                                                                                                        {bookedticket.eventDetails?.event_starts_Time}
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                                <div className="col-lg-3 col-md-3 col-sm-3 d-flex flex-column">
                                                                                                                    <span className="poppins15">Date</span>
                                                                                                                    <span className="poppins11">
                                                                                                                        {bookedticket?.eventreserveDate}
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <span className="poppins15 text-primary fw-semibold">{bookedticket.bookedTicketQuantities} Tickets</span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            {
                                                                                                                ticket.ticket_type === 'paid' && bookedticket.isPaid ? <span className="poppins11">Amount Paid</span> :
                                                                                                                    <span className="poppins11">Amount Not Paid</span>

                                                                                                            }
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="overlayticketScanner">
                                                                                                        <QRCodeSVG className="w-100 h-100"
                                                                                                            value={`${ticket._id}_${bookedticket.order_id}`} />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-lg-3 col-md-3 ps-5">
                                                                                                <div className="pb-2">
                                                                                                    <p className="poppins15 text-primary">Confirmation ID</p>
                                                                                                    <p className="poppins15 text-dark">{bookedticket.order_id}</p>
                                                                                                </div>
                                                                                                <div className="pb-2">
                                                                                                    <p className="poppins15 text-primary">Booking Date & Time</p>
                                                                                                    <p className="poppins15 text-dark"> {formatTimespanDate(bookedticket.createdAt)}</p>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <p className="poppins15 text-primary">Status</p>
                                                                                                    <p className="ticketStatus">{bookedticket.status}</p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div> : ''
                                                                                    ) : (
                                                                                        <div className="row ticket" key={ticket._id}>
                                                                                            <div className="col-lg-9 col-md-9 height">
                                                                                                <div className="ticket-img">
                                                                                                    <img src={Ticket} className="w-100 h-100" />
                                                                                                </div>
                                                                                                <div className="overlayOnImg">
                                                                                                    <div className="overlayeventImg">
                                                                                                        <img src={`${API_URL}/uploads/${bookedticket.eventDetails?.cover_image}`} className="w-100 h-100" />
                                                                                                    </div>
                                                                                                    <div className="overlayeventDetail">
                                                                                                        <div className="d-flex flex-column" style={{ height: "5.5rem" }}>
                                                                                                            <span className="DMSans22">
                                                                                                                {bookedticket.eventDetails?.event_name}
                                                                                                            </span>
                                                                                                            <span className="ticketName">
                                                                                                                {bookedticket.bookedTicketQuantities} x {ticket.ticket_Name}
                                                                                                            </span>
                                                                                                        </div>
                                                                                                        <div className="d-flex flex-column" style={{ borderBottom: "1.3px dashed #98999B" }}>
                                                                                                            <div className="row mt-3">
                                                                                                                <div className="col-lg-6 col-md-6 col-sm-6 d-flex flex-column">
                                                                                                                    <span className="poppins15">Venue</span>
                                                                                                                    <span className="poppins11">
                                                                                                                        {bookedticket.eventDetails?.event_venue} {bookedticket.eventDetails?.event_city}
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                                <div className="col-lg-3 col-md-3 col-sm-3 d-flex flex-column">
                                                                                                                    <span className="poppins15">Time</span>
                                                                                                                    <span className="poppins11">
                                                                                                                        {bookedticket.eventDetails?.event_starts_Time}
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                                <div className="col-lg-3 col-md-3 col-sm-3 d-flex flex-column">
                                                                                                                    <span className="poppins15">Date</span>
                                                                                                                    <span className="poppins11">
                                                                                                                        {formatDate(bookedticket.eventreserveDate ? bookedticket.eventreserveDate : bookedticket.eventDetails?.event_starts_date)}
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <span className="poppins15 text-primary fw-semibold">{bookedticket.bookedTicketQuantities} Tickets</span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <span className="poppins11">Free</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="overlayticketScanner">
                                                                                                        <QRCodeSVG className="w-100 h-100"
                                                                                                            value={`${ticket._id}_${bookedticket.order_id}`} />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-lg-3 col-md-3 ps-5">
                                                                                                <div className="pb-2">
                                                                                                    <p className="poppins15 text-primary">Confirmation ID</p>
                                                                                                    <p className="poppins15 text-dark">{bookedticket.order_id}</p>
                                                                                                </div>
                                                                                                <div className="pb-2">
                                                                                                    <p className="poppins15 text-primary">Booking Date & Time</p>
                                                                                                    <p className="poppins15 text-dark">{formatTimespanDate(bookedticket.createdAt)}</p>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <p className="poppins15 text-primary">Status</p>
                                                                                                    <p className="ticketStatus">{bookedticket.status}</p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                }
                                                                            </>
                                                                        )
                                                                    })
                                                                }

                                                            </div>
                                                        );
                                                    })
                                                }
                                                {/* Button to toggle ticket visibility */}
                                                {state.bookedevent.length > 1 && (
                                                    <div className="col-lg-10 col-md-10 col-sm-8 col-9 text-center d-flex justify-content-center mt-4">
                                                        <div className="w-25" style={{ padding: "0.5rem 0rem" }}>
                                                            <div style={{ border: "1px solid #2C9CF0" }}></div>
                                                        </div>
                                                        <span className="px-1 fw-semibold"
                                                            style={{ color: "#2C9CF0", fontFamily: "Poppins", fontSize: "12px", cursor: "pointer" }}
                                                            onClick={() => setShowAllTickets(!showAllTickets)}
                                                        >
                                                            {showAllTickets ? "Hide Previous Tickets" : "View Previous Tickets"}
                                                        </span>
                                                        <div className="w-25" style={{ padding: "0.5rem 0rem" }}>
                                                            <div style={{ border: "1px solid #2C9CF0" }}></div>
                                                        </div>

                                                    </div>
                                                )}
                                            </>
                                        ) : (
                                            <div>No tickets available</div>
                                        )
                                    )
                                }

                            </div>
                        </div>
                    </div>
                </div>
                <div className="mx-4" id="smallScreenView">
                    <div className='d-flex justify-content-center border-bottom border-primary' style={{ marginTop: "34px" }}>
                        <span className='profileheading fw-semibold'>Your Front Seats</span>
                    </div>
                    <Link to='/' className="back-home"><IoIosArrowBack /> Back to Home page</Link>
                    {/* <div className='mt-5  border-bottom border-secondary'>
                        <p className='poppins14 fw-semibold'>Booked on: 05th Apr,2024</p>
                    </div> */}
                    <div>
                        {
                            !state.bookedevent ? (
                                <div><Loader /></div>
                            ) : (
                                state.bookedevent.length > 0 ? (
                                    <>
                                        {
                                            // loop for booked event
                                            state.bookedevent.map((bookedticket, index) => {
                                                if (!showAllTickets && index > 0) return null;
                                                return (
                                                    <div key={bookedticket._id}>
                                                        {
                                                            bookedticket.tickets.map((ticket) => {
                                                                return (
                                                                    <>
                                                                        {
                                                                            ticket.ticket_type === 'paid' ? (bookedticket.isPaid ?
                                                                                <div className="card mt-3" key={ticket._id}>
                                                                                    <div className="card-body">
                                                                                        <div className="row p-3">
                                                                                            <div className="col-12">
                                                                                                <div className="d-flex flex-column">
                                                                                                    <span className="DMSans22 fw-semibold">
                                                                                                        {bookedticket.eventDetails?.event_name}
                                                                                                    </span>
                                                                                                    {ticket.bookingQuantities} x {ticket.ticket_Name}
                                                                                                </div>
                                                                                                <div className="d-flex flex-column">
                                                                                                    <div className="row mt-3">
                                                                                                        <div className="col-6 d-flex flex-column">
                                                                                                            <span className="poppins15">Venue</span>
                                                                                                            <span className="poppins11">
                                                                                                                {bookedticket.eventDetails?.event_venue} {bookedticket.eventDetails?.event_city}
                                                                                                            </span>
                                                                                                        </div>
                                                                                                        <div className="col-3 d-flex flex-column">
                                                                                                            <span className="poppins15">Time</span>
                                                                                                            <span className="poppins11">
                                                                                                                {bookedticket.eventDetails?.event_starts_Time}
                                                                                                            </span>
                                                                                                        </div>
                                                                                                        <div className="col-3 d-flex flex-column">
                                                                                                            <span className="poppins15">Date</span>
                                                                                                            <span className="poppins11">
                                                                                                                {formatDate(bookedticket.eventreserveDate ? bookedticket.eventreserveDate : bookedticket.eventDetails?.event_starts_date)}
                                                                                                            </span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <span className="poppins15 text-primary fw-semibold">{ticket.bookingQuantities}  Tickets</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            {/* <div className="col-3">
                                                                                                <QRCodeSVG className="w-100 h-100"
                                                                                                    value={`${ticket._id}_${bookedticket._id}`} />
                                                                                            </div> */}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="card-footer py-3 d-flex justify-content-between">
                                                                                        <span className="ticketStatus shadow">{bookedticket.status}</span>
                                                                                        <div>
                                                                                            <QRCodeSVG className="w-100 h-100"
                                                                                                value={`${ticket._id}_${bookedticket.order_id}`} />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                : '') :

                                                                                <div className="card mt-3" key={ticket._id}>
                                                                                    <div className="card-body">
                                                                                        <div className="row p-3">
                                                                                            <div className="col-12">
                                                                                                <div className="d-flex flex-column">
                                                                                                    <span className="DMSans22 fw-semibold">
                                                                                                        {bookedticket.eventDetails?.event_name}
                                                                                                    </span>
                                                                                                    {ticket.bookingQuantities} x {ticket.ticket_Name}
                                                                                                </div>
                                                                                                <div className="d-flex flex-column">
                                                                                                    <div className="row mt-3">
                                                                                                        <div className="col-6 d-flex flex-column">
                                                                                                            <span className="poppins15">Venue</span>
                                                                                                            <span className="poppins11">
                                                                                                                {bookedticket.eventDetails?.event_venue} {bookedticket.eventDetails?.event_city}
                                                                                                            </span>
                                                                                                        </div>
                                                                                                        <div className="col-3 d-flex flex-column">
                                                                                                            <span className="poppins15">Time</span>
                                                                                                            <span className="poppins11">
                                                                                                                {bookedticket.eventDetails?.event_starts_Time}
                                                                                                            </span>
                                                                                                        </div>
                                                                                                        <div className="col-3 d-flex flex-column">
                                                                                                            <span className="poppins15">Date</span>
                                                                                                            <span className="poppins11">
                                                                                                                {formatDate(bookedticket.eventreserveDate ? bookedticket.eventreserveDate : bookedticket.eventDetails?.event_starts_date)}
                                                                                                            </span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <span className="poppins15 text-primary fw-semibold">{ticket.bookingQuantities}  Tickets</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            {/* <div className="col-3">
                                                                                                <QRCodeSVG className="w-100 h-100"
                                                                                                    value={`${ticket._id}_${bookedticket._id}`} />
                                                                                            </div> */}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="card-footer py-3 d-flex justify-content-between">
                                                                                        <span className="ticketStatus shadow">{bookedticket.status}</span>
                                                                                        <div>
                                                                                            <QRCodeSVG className="w-100 h-100"
                                                                                                value={`${ticket._id}_${bookedticket.order_id}`} />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                        }


                                                                    </>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                )
                                            })
                                        }
                                        {state.bookedevent.length > 1 && (
                                            <div className="text-center d-flex justify-content-center mt-4">
                                                <div className="w-25" style={{ padding: "0.4rem 0rem" }}>
                                                    <div style={{ border: "1px solid #2C9CF0" }}></div>
                                                </div>
                                                <span className="mx-1 fw-semibold"
                                                    style={{ color: "#2C9CF0", fontFamily: "Poppins", fontSize: "10px" }}
                                                    onClick={() => setShowAllTickets(!showAllTickets)}
                                                >
                                                    {showAllTickets ? "Hide Previous Tickets" : "View Previous Tickets"}
                                                </span>
                                                <div className="w-25" style={{ padding: "0.4rem 0rem" }}>
                                                    <div style={{ border: "1px solid #2C9CF0" }}></div>
                                                </div>
                                            </div>

                                        )}
                                    </>
                                ) : (
                                    <div>No tickets available</div>
                                )
                            )
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default YourticketsPage
