import React, { useState } from "react";
import './CostumerPage.css'
import NavBar from "../Components/NavBar";
import LocationModal from "../Components/LocationModal";
import { useNavigate } from "react-router-dom";
import Footer from "../Components/Footer";
import { IoLocationOutline } from "react-icons/io5";
import { MdWifiCalling1 } from "react-icons/md";
import axios from "axios";
import { API_URL } from "../../config";
import SyncLoader from "react-spinners/SyncLoader";


const CostumerSupportPage = () => {
    const [userLocation, setUserLocation] = useState('Nagpur');
    const [showLocationModal, setShowLocationModal] = useState(false);
    const [query_type, setQuery_type] = useState("");
    const [customer_name, setCustomer_name] = useState("");
    const [customer_email, setCustomer_email] = useState("");
    const [mobile_number, setMobile_number] = useState("");
    const [message, setMessage] = useState("");
    const [loader, setLoader] = useState(false);




    // Function to handle location change
    const handleLocationChange = (location) => {
        setUserLocation(location);
        setShowLocationModal(false); // Close modal after location change
    };

    const handleSubmit = async () => {
        setLoader(true);
        try {
            const Query = {
                query_type: query_type,
                customer_name: customer_name,
                customer_email: customer_email,
                phone: mobile_number,
                message: message
            };

            const SendRequest = await axios.post(`${API_URL}/support/customer`, Query);
            if (SendRequest.status === 200) {
                alert("Query send successfully!")
                setLoader(false);
            }else{
                console.log(SendRequest.data.message)
            }
        } catch (error) {
            alert("Unable to send your Query!")
            setLoader(false);
        }
    }

    return (
        <>
            <div>
                <NavBar userLocation={userLocation} onNext={() => setShowLocationModal(true)} />
                {/* Render Location component as a modal */}
                {showLocationModal && (
                    <LocationModal
                        onClose={() => setShowLocationModal(false)}
                        onLocationChange={handleLocationChange}
                    />
                )}

                {/* Content */}
                <div className="row mb-5">
                    <div className="aboutHead">
                        <div className="text-center">
                            <h1 className="aboutHeading">Contact Us</h1>
                            {/* <p className="aboutSubHeading">Because your story deserves the best view.</p> */}
                        </div>
                    </div>

                    <div className="px-lg-5 px-3">
                        <div className="d-flex justify-content-between">
                            <div style={{ width: "55%" }}>
                                <p className="headingStyle mt-5">
                                    Write to us by filling in the form below
                                </p>
                                <form onSubmit={handleSubmit}>
                                    <div>
                                        <select className="form-control input_style" value={query_type} name="query_type" onChange={(e) => setQuery_type(e.target.value)}>
                                            <option value="customer_query" defaultValue>For Customer Queries</option>
                                            <option value="business_query">For Business Queries</option>
                                            <option value="other_query">For Other Queries</option>
                                            <option value="first_event">I want to host my first event</option>
                                        </select>
                                    </div>
                                    <div className="mt-3">
                                        <label className="ms-1" for="customer_name">Name</label>
                                        <input
                                            className="form-control input_style"
                                            value={customer_name}
                                            name="customer_name"
                                            onChange={(e) => { setCustomer_name(e.target.value) }} />
                                    </div>
                                    <div className="mt-3 row">
                                        <div className="col-lg-6">
                                            <label className="ms-1" for="customer_name">Email</label>
                                            <input
                                                className="form-control input_style"
                                                value={customer_email}
                                                name="customer_email"
                                                onChange={(e) => { setCustomer_email(e.target.value) }} />
                                        </div>
                                        <div className="col-lg-6">
                                            <label className="ms-1" for="mobile_number">Mobile Number</label>
                                            <input
                                                type="tel"
                                                className="form-control input_style"
                                                value={mobile_number}
                                                name="mobile_number"
                                                onChange={(e) => { setMobile_number(e.target.value) }} />
                                        </div>
                                    </div>
                                    <div className="mt-3">
                                        <label className="ms-1" for="customer_name">Message</label>
                                        <textarea
                                            className="form-control input_style"
                                            value={message}
                                            name="message"
                                            onChange={(e) => { setMessage(e.target.value) }}></textarea>
                                    </div>
                                    <div className="mt-3">
                                        <button className="btn_primary">{
                                            loader ? <SyncLoader animation="border" color="#FFFF" size="7" speedMultiplier={1} margin={4} /> : "Submit"}</button>
                                    </div>
                                </form>
                            </div>
                            <div style={{ width: "35%" }}>
                                <p className="headingStyle mt-5">
                                    Contact Us
                                </p>
                                <div className="card">
                                    <div className="card-body">
                                        <p className="d-flex">
                                            <span className="me-1"><IoLocationOutline size={24} /></span>
                                            <span className="d-flex flex-column">
                                                <span> 107, Dighori,</span>
                                                <span>Umrer Road,</span>
                                                <span>Nagpur 440034, Maharastra. </span>
                                            </span>
                                        </p>
                                        <hr />
                                        <p>
                                            <span className="me-1"><MdWifiCalling1 size={24} /></span>
                                            <span>+91 7028260520</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>


                <Footer />
            </div>
        </>
    )
}

export default CostumerSupportPage;