import { useEffect, useRef } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth'; // Import signOut method
import './App.css';
import HomePage from './User/Pages/HomePage';
import { Route, Routes } from 'react-router-dom';
import CategoryPage from './User/Pages/CategoryPage';
import ProductPage from './User/Pages/ProductPage';
import TicketPage from './User/Pages/TicketPage';
import BookingPage from './User/Pages/BookingPage';
import LoginPage from './User/Pages/LoginPage';
import AboutUsPage from './User/Pages/AboutUsPage';
import SignUpPage from './User/Pages/SignUpPage';
import PrivacyPolicy from './User/Pages/PrivacyPolicy';
import TermsCondition from './User/Pages/TermsCondition';
import UserProfile from './User/Pages/UserProfile';
import YourticketsPage from './User/Pages/YourTicketPage';
import ListyourEventPage from './User/Pages/ListyourEventPage';
import HostedEventPage from './User/Pages/HostedEventPage';
import auth from './firebaseConfig';
import SucessBookingTicketPage from './User/Pages/SucessBookingTicketPage';
// import PaymentGateway from './User/Pages/PaymentGateway';
import DateSelect from './User/Components/DateSelect';
import CostumerSupportPage from './User/Pages/CostumerSupportPage';

function App() {
  const navigate = useNavigate();
  const inactivityTimer = useRef(null);
  const user = JSON.parse(localStorage.getItem('userData'));

  const isAuthenticated = () => {
    return !!localStorage.getItem('userData'); // This is just an example; implement your own auth logic
  };
  
  const PrivateRoute = ({ element: Component }) => {
    return isAuthenticated() ? Component : <Navigate to="/login?source=yourtickets" />;
  };
  
  // Function to log out the user
  const logoutUser = () => {
    signOut(auth).then(() => {
      // Redirect to login page after sign-out
      navigate('/login');
    }).catch((error) => {
      console.error('Sign out error', error);
    });
  };

  // Function to reset the inactivity timer
  const resetTimer = () => {
    // Clear the existing timer
    if (inactivityTimer.current) {
      clearTimeout(inactivityTimer.current);
    }
    // Set a new timer (e.g., 1 hour = 3600000 milliseconds)
    inactivityTimer.current = setTimeout(logoutUser, 3600000);
  };

  // Set up event listeners for user activity
  useEffect(() => {
    // Events to listen for user activity
    const events = ['click', 'mousemove', 'keypress', 'scroll'];

    // Add event listeners for each event type
    events.forEach((event) => {
      window.addEventListener(event, resetTimer);
    });

    // Set the initial timer
    resetTimer();

    // Clean up event listeners and timer on component unmount
    return () => {
      events.forEach((event) => {
        window.removeEventListener(event, resetTimer);
      });
      if (inactivityTimer.current) {
        clearTimeout(inactivityTimer.current);
      }
    };
  }, []); // Empty dependency array ensures this runs only on mount/unmount

  return (
    <div>
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/signup" element={<SignUpPage />} />
        <Route path="/aboutus" element={<AboutUsPage />} />
        <Route path="/contact" element={<CostumerSupportPage />} />
        <Route path="/category/:category" element={<CategoryPage />} />
        <Route path="/productpage/:id" element={<ProductPage />} />  {/*event_city/event_name/:id */}
        <Route path="/:event_city/:event_name/:id" element={<ProductPage />} />  {/*event_city/event_name/:id */}
        <Route path="/ticketpage/:event_name/:id" element={<TicketPage />} />
        <Route path="/dateselect/:event_name/:id" element={<DateSelect />} />
        <Route path="/eventbooking/:event_name/:event_id" element={<BookingPage />} />
        <Route path="/policy" element={<PrivacyPolicy />} />
        <Route path="/terms-condition" element={<TermsCondition />} />
        <Route path="/userprofile" element={<UserProfile />} />
        <Route path="/yourtickets" element={<PrivateRoute element={<YourticketsPage />} />} />
        <Route path="/yourtickets" element={<YourticketsPage />} />
        <Route path="/listyourevent" element={<ListyourEventPage />} />
        <Route path="/ticketbooked/:event_name" element={<SucessBookingTicketPage />} />
        <Route path="/hosted-events" element={<HostedEventPage />} />
        {/* <Route path="/paymentgetway" element={<PaymentGateway />} /> */}
      </Routes>
    </div>
  );
}

export default App;
