import React, { useEffect } from 'react';
import './Carousel.css';
import CaraousalsImages from '../../Assets/CaraousalsImages.js';

function Carousel() {

  return (
    <>
      <div id="carouselExampleSlidesOnly" className="carouselDiv carousel slide"  data-bs-ride="carousel" data-bs-interval="5000" data-bs-touch="true"  data-bs-pause="false">
        <div className="carousel-indicators">
          {CaraousalsImages.map((_, index) => (
            <button
              key={index}
              type="button"
              data-bs-target="#carouselExampleSlidesOnly"
              data-bs-slide-to={index}
              className={index === 0 ? 'active' : ''}
              aria-current={index === 0 ? 'true' : 'false'}
              aria-label={`Slide ${index + 1}`}
            ></button>
          ))}
        </div>
        <div className="carousel-inner innercarouselDiv">
          {CaraousalsImages.map((image, index) => (
            <div key={image.id} className={`carousel-item carouselimgDiv px-1 ${index === 0 ? 'active' : ''}`} data-bs-interval="5000">
              <img src={image.image} alt={`Banner ${index + 1}`} className="d-block cover-image" />
            </div>
          ))}
        </div>
      </div>

    </>
  );
}

export default Carousel;
